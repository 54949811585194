<template>
  <div v-if="params" id="userInfo">
    <div class="header van-hairline--top">
      <img class="header-logo" :src="user_avatar" alt="">
      <div class="header-info">
        <!-- 暂无昵称 -->
        <span class="header-name van-ellipsis">{{params.username ? params.username : language.l_304}}</span>
        <!-- 主人有点懒，还未添加简介 -->
        <span class="header-date">{{params.desc ? params.desc : language.l_305}}</span>
      </div>
    </div>
    <div class="container">
      <van-cell-group>
        <!-- 用户名 -->
        <van-cell :title="language.l_294" :value="params.username" />
        <!-- 美翻昵称 -->
        <van-cell :title="language.l_306" :value="params.nickname" />
        <!-- 手机号 -->
        <van-cell :title="language.l_307" :value="params.tel" />
        <!-- 简介 -->
        <van-cell :title="language.l_310" :value="params.desc" />
      </van-cell-group>
    </div>
    <div class="footer">
      <!-- 修改用户信息 -->
      <p @click="modify" class="footer-btn" >{{language.l_308}}</p>
      <!-- 退出当前账号 -->
      <p @click="loginOut" class="footer-btn footer_buttom">{{language.l_309}}</p>
    </div>
  </div>
</template>

<script>
import {log_out, getUserInfo} from '@api';

export default {
  data() {
    return {
      params: null,
      language: this.$store.getters.language
    }
  },
  created() {
    getUserInfo().then(res => {
      this.params = res.data.user
      this.$store.commit('setUserInfo', res.data.user)
      this.$Cookies.set('setUserInfo', res.data.user)
    })
  },
  methods: {
    loginOut() { // 退出登录
      log_out().then(() => {
        // 清除cookies登录信息
        this.$Cookies.remove('setToken')
        this.$Cookies.remove('setUserInfo')

        // 清除vuex登录信息
        this.$store.commit('setToken');
        this.$store.commit('setUserInfo', '');
        this.$toast.success(this.language.l_311)  //退出成功
        this.$store.commit('setDelayJump', () => this.$router.push({name: 'Home'}))
      })
    },
    modify(){   //修改用户
      this.$router.push({name: 'UpdateInfo'})
    },
  },
  computed: {
    user_avatar() {return this.params.avatar ? this.params.avatar : require('@img/home/user_avatar.png')}
  }
}
</script>

<style lang="less" scoped>
#userInfo {
  .header {
    display: flex;
    background-color: #fff;
    box-sizing: border-box;
    padding: 30px 10px 20px;
    .header-logo {
      width: 60px;
      height: 60px;
      border-radius: 50%;
    }
    .header-info {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 160px;
      margin-left: 10px;
      font-size: 12px;
      color: #ccc;
      .header-name {
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 10px;
        color: #333;
      }
    }
  }

  .container {
    background-color: #fff;
    font-size: 14px;
  }
  .footer {
    .footer-btn {
      height: 45px;
      margin: 100px 15px 0;
      font-size: 16px;
      line-height: 45px;
      text-align: center;
      color: #fff;
      border-radius: 5px;
      background-image: @grad_right;
    }
    .footer_buttom {
       margin: 10px 15px 0;
       background: @c_light;
    }
  }
}
.buttom {
  .van-button--default {
    height: 45px;
    font-size: 16px;
    line-height: 45px;
    text-align: center;
    color: #fff;
    border-radius: 5px;
    background-image: @grad_right;
  }
  .buttom_color {
    margin-top: 8px;
    background: @c_light;
  }
}
.head_icon {
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>